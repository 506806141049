import React from 'react'
import { Layout } from '../components/common';

const Index = () => {
  return (
    <div>
      <Layout isHome={true}>

      </Layout>
    </div>
  )
}

export default Index;
